<!--/**-->
<!--* 自动生成 vue列表代码【模块名：库位】-->
<!--* 生成日期：2024/04/25 11:20:49-->
<!--* 生成路径: src/pages/warehouse_location/WarehouseLocationList.vue-->
<!--* 生成人：admin-->
<!--*/-->
<template>
  <page-header-layout>
    <warehouse-location-print ref='print' />
    <div class='main-page-content'>
      <div class='table-search'>
        <el-form :model='searchCondition' inline size='mini'>
          <el-form-item label='仓库id' style='display: none'>
            <el-input v-model='searchCondition.wh_id' placeholder='仓库id' clearable></el-input>
          </el-form-item>
          <el-form-item label='库位编号'>
            <el-input v-model='searchCondition.whl_code' placeholder='库位编号' clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type='primary' icon='el-icon-search' :disabled='loading' :loading='loading'
                       @click='handleQuery'>搜索
            </el-button>
            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>
            <el-button type='primary' icon='el-icon-plus' @click='handleBatchAdd'>新增货架</el-button>
            <el-button icon='el-icon-upload' @click='openImport=true'>导入</el-button>
          </el-form-item>
        </el-form>
      </div>
      <!--        操作栏-->
      <!--        <el-row class='mb8' :gutter='10'>-->
      <!--          <el-col :span='1.5'>-->
      <!--            <el-button type='primary' icon='el-icon-plus' @click='handleAdd'>新增</el-button>-->
      <!--          </el-col>-->
      <!--          <el-col :span='1.5'>-->
      <!--            <el-button icon='el-icon-upload' @click='openImport=true'>导入</el-button>-->
      <!--          </el-col>-->
      <!--          <el-col :span='1.5'>-->
      <!--            <export-btn ref='refLogisticSubscribeExport' :can-export='hasAuthority("warehouse_location_export")' @export='handleExport'></export-btn>-->
      <!--          </el-col>-->
      <!--        </el-row>-->
      <!--    渲染表格-->
      <div class='default-table' style='height: calc(100% - 50px);'>
        <MlPageTable ref='table'
                     :all-columns='defaultColumns'
                     :table-name='tableName'
                     :data-list='dataList'
                     :limit.sync='pageData.page_size'
                     :page.sync='pageData.current_page'
                     :total.sync='total'
                     height='100%'
                     highlight-current-row
                     :row-class-name='tableRowClassName'
                     @row-click='rowClickEvent'
                     @getList='getList'>
          <el-table-column slot='first-column' type='selection' width='50' align='center' />
          <el-table-column align='center' width='120' label='操作'>
            <template slot-scope='{row,$index}'>
              <el-button type='text' icon='el-icon-edit' @click='handleEdit(row)'>修改</el-button>
              <el-button type='text' icon='el-icon-delete' @click='handleDelete(row,$index)'>删除</el-button>
            </template>
          </el-table-column>

          <template slot='footer'>
            <el-button type='primary' icon='el-icon-printer' @click='handlePrint'>批量打印</el-button>
          </template>
        </MlPageTable>
      </div>
    </div>

    <!-- 添加或修改对话框 -->
    <el-dialog :title='title' :visible.sync='open' width='800px' append-to-body :close-on-click-modal='false'>
      <el-form ref='form' :model='form' :rules='rules' label-width='100px'>
        <el-form-item label='仓库id' prop='wh_id' style='display: none;'>
          <el-input v-model='form.wh_id' placeholder='请输入仓库id' clearable />
        </el-form-item>
        <el-form-item label='库位编号' prop='intact_whl_code'>
          <el-input v-model='form.intact_whl_code' placeholder='请输入库位编号' clearable />
        </el-form-item>
        <el-form-item label='负责人' prop='leader_name'>
          <el-input v-model='form.leader_name' placeholder='请输入负责人' clearable />
        </el-form-item>
        <el-form-item label='备注' prop='remark'>
          <el-input v-model='form.remark' placeholder='请输入备注' clearable />
        </el-form-item>
        <!--          <el-form-item label='库位码图片ID' prop='cover'>-->
        <!--            <el-input v-model='form.cover' placeholder='请输入库位码图片ID' clearable/>-->
        <!--          </el-form-item>-->
        <el-form-item label='状态' prop='state'>
          <el-select v-model='form.state' placeholder='请选择状态' clearable>
            <el-option :label='option.label' :value='option.value' :key='key'
                       v-for='(option, key) in stateOptions'></el-option>
          </el-select>
        </el-form-item>

      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
        </el-button>
        <el-button @click='cancel'>取 消</el-button>
      </div>
    </el-dialog>
    <!-- 新增货架 -->
    <el-dialog title='新增货架' :visible.sync='batchOpen' width='800px' append-to-body :close-on-click-modal='false'>
      <el-form ref='batchForm' :model='batchForm' :rules='rules' label-width='100px'>
        <el-form-item label='自动编号'></el-form-item>
        <div style='border: 1px solid #0C0C0C; padding: 10px 10px;'>
          <el-form-item label='仓库id' prop='wh_id' style='display: none;'>
            <el-input v-model='batchForm.wh_id' placeholder='请输入仓库id' clearable />
          </el-form-item>

          <el-form-item label='货架规则'>
            <el-form-item prop='shelves_no' class='el-form-item-inline'>
              <el-input v-model='batchForm.shelves_no' placeholder='货架区域；如：A/AB' @change='handleChangePreview'
                        clearable />
            </el-form-item>
            <span style='margin-left: 5px;margin-right: 5px'>X</span>
            <el-form-item prop='layer' class='el-form-item-inline' style='width: 20.5%'>
              <el-input-number v-model='batchForm.layer' :min='1' label='货架层数' @change='handleChangePreview'
                               placeholder='货架层数' />
            </el-form-item>
            <span style='margin-left: 5px;margin-right: 5px'>+</span>
            <el-form-item prop='group' class='el-form-item-inline'>
              <el-input-number v-model='batchForm.group' :min='1' label='共几组' @change='handleChangePreview'
                               placeholder='共几组' />
            </el-form-item>
          </el-form-item>
          <el-form-item label='负责人' prop='leader_name' style='width: 50%'>
            <el-input v-model='batchForm.leader_name' placeholder='请填写负责人' clearable />
          </el-form-item>
          <el-form-item label='预览' prop='remark'>
            <el-input type='textarea' v-model='batchForm.preview' autosize readonly />
          </el-form-item>
        </div>

      </el-form>
      <div slot='footer' class='dialog-footer'>
        <el-button type='primary' @click='submitBatchForm' :disabled='loadingCommit' :loading='loadingCommit'>确 定
        </el-button>
        <el-button @click='batchCancel'>取 消</el-button>
      </div>
    </el-dialog>
    <!--      导入-->
    <ImportWarehouseLocation :visible.sync='openImport' v-if='hasAuthority("logistic_subscribe_import")'
                             @imported='handleQuery' />
  </page-header-layout>
</template>

<script>
import MlPageTable from '@/components/common/MlPageTable'
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import ExportBtn from '@/components/export/ExportBtn'
import ImportWarehouseLocation from './ImportWarehouseLocation'
import WarehouseLocationPrint from './Print'

export default {
  name: 'WarehouseLocationList',
  components: { WarehouseLocationPrint, ImportWarehouseLocation, ExportBtn, PageHeaderLayout, MlPageTable },
  data() {
    return {
      tableName: 'warehouse_location',
      loading: false,
      loadingCommit: false,
      dataList: [],
      total: 0,
      searchCondition: { wh_id: null, whl_code: null, shelves_no: null },
      pageData: { current_page: 1, page_size: 100 },
      orderBy: {},
      stateOptions: {
        0: { label: '禁用', value: 0, color_value: '#f56c6c' },
        1: { label: '启用', value: 1, color_value: '#67c23a' }
      },
      defaultColumns: [{ hide: false, prop: 'intact_whl_code', label: '库位编号', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'wh_name', label: '仓库名称', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'leader_name', label: '负责人', width: 120, sortable: true, fixed: false },
        { hide: false, prop: '_state', label: '状态', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'remark', label: '备注', width: 120, sortable: true, fixed: false },
        { hide: false, prop: 'created_at', label: '创建时间', width: 120, sortable: false, fixed: false }],
      // 弹出层标题
      title: '添加/修改-库位',
      // 是否显示弹出层
      open: false,
      batchOpen: false,
      // 表单校验
      rules: {
        wh_id: [{ required: true, message: '仓库id不能为空', trigger: 'blur' }],
        wh_name: [{ required: true, message: '仓库名称不能为空', trigger: 'blur' }],
        intact_whl_code: [{ required: true, message: '库位编号不能为空', trigger: 'blur' }],
        whl_code: [{ required: true, message: '库位编号不能为空', trigger: 'blur' }],
        shelves_no: [{ required: true, message: '货架号不能为空', trigger: 'blur' }],
        leader_name: [{ required: true, message: '负责人不能为空', trigger: 'blur' }],
        remark: [{ message: '备注不能为空', trigger: 'blur' }],
        cover: [{ message: '库位码图片ID不能为空', trigger: 'blur' }],
        state: [{ required: true, message: '状态不能为空', trigger: 'blur' }],
        layer: [{ required: true, message: '货架层数不能为空', trigger: 'blur' }],
        group: [{ required: true, message: '货架组数不能为空', trigger: 'blur' }]
      },
      // 表单参数
      form: {},
      batchForm: {},
      //是否显示弹出层:导入框
      openImport: false,
      //筛选条件缓存key
      pageSearchKey: 'page_search_key:warehouse_location'
    }
  },
  methods: {
    // eslint-disable-next-line no-unused-vars
    rowClickEvent(row, column, event) {
      // this.$notify.info(`数据ID：${row.id}`)
    },
    // eslint-disable-next-line no-unused-vars
    tableRowClassName({ row, rowIndex }) {
      return 'default-table-row'
    },
    handleSearchCondition() {
      let condition = {}
      Object.keys(this.searchCondition).forEach((field) => {
        if (field !== 'date_range' && this.searchCondition[field]) {
          // console.log(Object.prototype.toString.call(this.searchCondition[field]))
          // console.log((this.searchCondition[field]).constructor)
          // console.log(typeof (this.searchCondition[field]))

          condition[field] = this.searchCondition[field]
        }
      })

      if (this.searchCondition.date_range) {
        condition.date_range = this.searchCondition.date_range
      }

      // 分页 +排序
      Object.assign(condition, this.pageData, { orderBy: this.orderBy })

      return condition
    },
    handleQuery() {
      this.pageData.current_page = 1
      this.getList()
      //缓存筛选条件，刷新后可以继续使用
      sessionStorage.setItem(this.pageSearchKey, JSON.stringify(this.searchCondition))
    },
    async getList() {
      this.dataList = []
      this.loading = true
      let params = this.handleSearchCondition()
      let { list, pages } = await this.$api.getWarehouseLocationList(params)
      // this.dataList = [...this.dataList, ...list]
      this.dataList = list
      this.pageData.current_page = pages.current_page || 1
      this.pageData.page_size = pages.page_size || 100
      this.total = pages.total
      this.loading = false
    },
    /** 删除按钮 */
    handleDelete(row, index) {
      if (row.id) {
        //真实删除
        this.$confirm('此操作将删除该行数据, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.delRow(row.id)
          this.dataList.splice(index, 1)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
      } else {
        this.tableData.splice(index, 1)
      }
    },
    delRow(id) {
      this.$api.deleteWarehouseLocation(id)
    },
    /** 修改按钮 */
    handleAdd() {
      this.open = true
      this.title = '添加-库位'
      // let { info } = await this.$api.getWarehouseLocationInfo(row.id)
      this.form = {
        wh_id: parseInt(this.$route.query.wh_id)
      }
    },
    handleBatchAdd() {
      this.batchOpen = true
      this.batchForm = {
        wh_id: parseInt(this.$route.query.wh_id),
        state: 1
      }
    },
    /** 修改按钮 */
    async handleEdit(row) {
      this.open = true
      this.title = '修改-库位'
      // let { info } = await this.$api.getWarehouseLocationInfo(row.id)
      this.form = { ...row }
    },

    /** 提交按钮 */
    async submitForm() {
      this.$refs['form'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.saveWarehouseLocation(this.form).then(id => {
            if (id) {
              if (!this.form.id)
                this.msgSuccess('新增成功')
              else
                this.msgSuccess('修改成功')

              this.form.id = id
              this.open = false
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })

        }
      })
    },
    // 取消按钮
    cancel() {
      this.open = false
      this.form = {}
    },
    batchCancel() {
      this.batchOpen = false
      this.batchForm = {}
    },
    //导出表格
    async handleExport() {
      try {
        let searchCondition = this.handleSearchCondition()
        let response = await this.$api.exportWarehouseLocations(searchCondition)
        let name = `库位`

        this.$refs.refLogisticSubscribeExport.exportSaveXlsx(response, name)
      } catch (e) {
        this.$message.warning('导出异常，请联系管理员')
        // alert(JSON.stringify(e))
        this.$refs.refLogisticSubscribeExport.hideLoading()
      }
    },
    /** 批量提交按钮 */
    async submitBatchForm() {
      this.$refs['batchForm'].validate(valid => {
        if (valid) {
          this.loadingCommit = true
          this.$api.batchSaveWarehouseLocation(this.batchForm).then((data) => {
            if (data) {
              this.msgSuccess('新增成功')
              this.batchOpen = false
              this.batchForm = {}
              this.getList()
            } else {
              this.msgError('操作失败')
            }
            setTimeout(() => {
              this.loadingCommit = false
            }, 500)
          })
        }
      })
    },
    handleChangePreview() {
      let isValid = true
      let valid = ['wh_id', 'shelves_no', 'layer', 'group']
      valid.map((k) => {
        if (!this.batchForm[k]) {
          isValid = false
        }
      })
      if (isValid) {
        this.$api.previewLocationBatchAdd(this.batchForm).then((data) => {
          this.batchForm.preview = ''
          if (data.length > 0) {
            let preview = ''
            data.forEach(item => {
              preview = preview + item.whl_code + '\n'
            })
            this.batchForm.preview = preview
          }
        })
      }
    },
    handlePrint: function() {
      const selection = this.$refs.table.interfaceGetSelection()
      if (selection.length < 1) {
        this.$message.warning('请选择要打印的库位')
        return
      }
      const locations = selection.map(_ => {
        return `${_.shelves_no}-${_.whl_code}`
      })
      // 打印
      this.$refs.print.print(locations)
    }
  },
  created() {
    if (sessionStorage.getItem(this.pageSearchKey)) {
      this.searchCondition = JSON.parse(sessionStorage.getItem(this.pageSearchKey))
    }
    // 设置默认仓库id
    if (this.$route.query) {
      this.searchCondition.wh_id = parseInt(this.$route.query.wh_id)
    }
    this.handleQuery()
  }
}
</script>

<style scoped>
.el-form-item-inline {
    display: inline-block;
    width: 30%;
}
</style>

<style lang='scss'>
.warehouse_location_main-inner {
  height: calc(100vh - 90px);

  &__content {
    height: 100%;
  }

  .page-content {
    height: calc(100% - 38px);
    min-height: unset !important;

    .main-page-content {
      height: calc(100% - 24px);
      min-height: unset !important;
    }
  }

  .ml-page-table {
    height: calc(100% - 20px);

    .default-table {
      height: calc(100% - 20px);
    }

    .pagination-container {
      padding: 0 !important;
    }
  }
}
</style>